//fontawesome読み込み
(function () { var css = document.createElement('link'); css.href = 'https://use.fontawesome.com/releases/v5.1.0/css/all.css'; css.rel = 'stylesheet'; css.type = 'text/css'; document.getElementsByTagName('head')[0].appendChild(css); })();

//googlefont読み込み
var WebFont = require('webfontloader');
WebFont.load({
  google: {
    families: [
      'Abel',
      'Lato:400,500,700,900',
      'Noto+Sans+JP'
    ],
  }
});

//ハンバーガーメニュー

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
//document.querySelector('.header__nav--inner').disableBodyScroll();
const targetElement = document.querySelector('.header__nav');

(function () {
  'use strict';

  var acdHeads;
  var acdBody;

  // マッチしたclass名を持つ要素を取得

  acdHeads = document.querySelectorAll('.header--togglebtn');
  acdBody = document.querySelector('.header__nav');

  for (var i = 0; i < acdHeads.length; i++) {
    acdHeads[i].addEventListener('click', function () {
      disableBodyScroll(targetElement);

      //console.log('click');
      console.log(acdBody);
      //var acdBody;

      // 同じ親要素を持つ隣接した次の要素を取得
      // <div class="acd_menu_body"></div>
      //acdBody = this.nextElementSibling;
      acdBody.style.transition = 'height 0.5s ease-out';

      if (acdBody.style.height) {
        acdBody.style.height = null;
        $('.header__toggle span').removeClass('is-active');
        enableBodyScroll(targetElement);
      } else {
        // scrollHeightプロパティはpaddingを含む表示されていない要素の高さを取得
        acdBody.style.height = acdBody.scrollHeight + 'px';
      }
    });
  }

  var ham,
    hamItems;

  ham = document.getElementById('ham');
  hamItems = document.querySelectorAll('.ham_menu_item');
  ham.addEventListener('click', function () {
    for (var i = 0; i < hamItems.length; i++) {
      if (hamItems[i].classList.contains('is-active')) {
        hamItems[i].classList.remove('is-active');
      } else {
        hamItems[i].classList.add('is-active');
      }
      // 上記if文を削除し、下記コードをアクティブにしても実行可能
      // hamItems[i].classList.toggle('is-active');
    }
  });
}());

